import React from "react";

const FAQ = () => {
  return (
    <div className="Container">
      <div></div>
    </div>
  );
};
export default FAQ;
